<template>
  <b-card no-body title="Patients">
    <div>
      <b-button-toolbar justify class="toolbar-top-margin">
        <b-form-group label-align-sm="left" label-size="md" label-for="filterInput" class="mb-0 search-bar-margin">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filterBy" type="search" placeholder="Type to Search" debounce="500" />
          </b-input-group>
        </b-form-group>
        <b-button-group class="mr-2 mb-1" horizontal>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon" v-b-modal.add-dispensay-partner v-b-tooltip.hover>
            <feather-icon icon="PlusIcon" />
          </b-button>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon" @click="initalLoadPartners" v-b-tooltip.hover title="Refresh">
            <b-spinner v-if="getLoading" small style="margin-bottom: 3px" />
            <feather-icon icon="RefreshCwIcon" v-if="!getLoading" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
      <b-table responsive :fields="fields" :items="getPartnerList" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="isSortDesc" sort-icon-left no-local-sorting>
        <template #cell(active)="data">
          <template v-if="data.value">
            <feather-icon icon="CheckCircleIcon" size="20" class="text-success mr-1" />
          </template>
          <template v-else>
            <feather-icon icon="XCircleIcon" size="20" class="text-danger mr-1" />
          </template>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <div class="col-1">
          <b-form-select id="perPageSelect" :value="getPerPage" size="sm" inline :options="pageOptions" @change="perPageChanged" />
          <p class="text-nowrap mb-md-0 mr-1"><b>Total rows:</b> {{ getTotalRows }}</p>
        </div>

        <!-- pagination -->
        <div>
          <b-pagination
            :value="currentPage"
            :total-rows="getTotalRows"
            :per-page="getPerPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            limit="1"
            hide-ellipsis
            hide-goto-end-buttons
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-overlay>
    <b-modal
      ref="modalRef"
      id="add-dispensay-partner"
      centered
      header-bg-variant="info"
      title="Add partner integration"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      no-stacking
      scrollable
    >
      <b-form-group id="integration-parent" label-for="parent-select" label="Partner POS" description="Pick dispensary partner POS" label-cols="3" label-align="right">
        <b-form-select id="parent-select" v-model="partnerData.integrationUid" :options="integrationsList" value-field="uid" text-field="name"> </b-form-select>
      </b-form-group>

      <b-form-group
        id="integration-name"
        label-for="name-input"
        label="Partner name"
        description="Enter dispensary name"
        label-cols="3"
        label-align="right"
        :state="partnerData.name.length > 4"
      >
        <b-form-input id="name-input" :state="partnerData.name.length > 4" v-model="partnerData.name" required></b-form-input>
      </b-form-group>
      <b-form-group
        id="integration-contact-name"
        label-for="contact-name-input"
        label="Contact name"
        description="Enter dispensary contact"
        label-cols="3"
        label-align="right"
        :state="partnerData.contactName.length > 4"
      >
        <b-form-input id="contact-name-input" :state="partnerData.contactName.length > 4" v-model="partnerData.contactName" required></b-form-input>
      </b-form-group>
      <b-form-group
        id="integration-contact-email"
        label-for="contact-email-input"
        label="Contact email"
        label-cols="3"
        label-align="right"
        :state="partnerData.contactEmail.length > 4"
      >
        <b-form-input id="contact-email-input" v-model="partnerData.contactEmail" required :state="partnerData.contactEmail.length > 4"></b-form-input>
      </b-form-group>
      <b-form-group
        id="integration-contact-phone"
        label-for="contact-phone-input"
        label="Contact Phone"
        label-cols="3"
        label-align="right"
        :state="partnerData.contactPhone.length > 4"
      >
        <b-form-input id="contact-phone-input" v-model="partnerData.contactPhone" required :state="partnerData.contactPhone.length > 4"></b-form-input>
      </b-form-group>

      <b-form-group
        id="integration-api-endpoint"
        label-for="api-endpoint-input"
        label="API Endpoint"
        label-cols="3"
        label-align="right"
        :state="partnerData.apiEndpoint.length > 4"
        v-b-tooltip.hover
        title="Contact support to get partner dispensary API details"
      >
        <b-form-input id="api-endpoint-input" v-model="partnerData.apiEndpoint" required :state="partnerData.apiEndpoint.length > 4"></b-form-input>
      </b-form-group>
      <b-form-group id="integration-api-key" label-for="api-key-input" label="Api Key" label-cols="3" label-align="right" :state="partnerData.apiKey.length > 4">
        <b-form-input id="api-key-input" v-model="partnerData.apiKey" required :state="partnerData.apiKey.length > 4"></b-form-input>
      </b-form-group>

      <template #modal-footer>
        <b-button size="sm" variant="danger" @click="$bvModal.hide('add-dispensay-partner')" :disabled="saving"> Cancel </b-button>
        <b-button size="sm" variant="outline-warning" @click="savePartner()" :disabled="saving">
          Save
          <b-spinner v-if="saving" small style="margin-bottom: 3px" />
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import {
  getPartnerList,
  lookupIntegration,
  filterBy,
  sortBy,
  sortOrder,
  setPerPage,
  getLoading,
  onLoadAllPartners,
  getTotalRows,
  getPerPage,
  saveNewPartner
} from "./compassionPartnerRequests";
import { getUserData } from "@/auth/utils";
import { DEFAULT_PAGE_OPTIONS } from "@/listo/defaults";
import { onMounted, ref, reactive, watch } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { useRouter } from "@core/utils/utils";

export default {
  name: "Partners",
  components: {},
  directives: {
    Ripple
  },
  setup(_, { root }) {
    const pageOptions = DEFAULT_PAGE_OPTIONS;
    const currentPage = ref(1);
    const isSortDesc = ref(false);
    const saving = ref(false);
    const modalRef = ref(null);
    const integrationsList = ref([]);
    const partnerData = ref({
      integrationUid: "",
      name: "",
      apiEndpoint: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      apiKey: ""
    });

    const fields = [
      { key: "integrationName", label: "Integration" },
      { key: "name", label: "Dispensary" },
      { key: "contactName", label: "Contact" },
      { key: "apiEndpoint", label: "API Endpoint" },
      "active"
    ];

    const { router } = useRouter();

    const isUserAdmin = () => getUserData && getUserData.admin;

    const savePartner = async () => {
      console.log("save partner", partnerData);
      saving.value = true;
      //modalRef.value.hide();
      try {
        await saveNewPartner(partnerData.value);
      } catch (err) {
        console.error(err);
      } finally {
        saving.value = false;
        modalRef.value.hide();
        await initalLoadPartners();
      }
    };

    const initalLoadPartners = async () => {
      console.debug("loading partners");
      try {
        await onLoadAllPartners({ prevPage: false, nextPage: false, reset: true });
      } catch (err) {
        root.$bvToast.toast(`Error loading datat:  ${err.message}`, {
          title: "Error",
          autoHideDelay: 4000,
          variant: "danger",
          solid: true,
          appendToast: true
        });
      }
    };

    const perPageChanged = async (val) => {
      setPerPage(val);
      await initalLoadUsers();
    };

    const onPageChanged = async (val) => {
      if (val == 1) {
        currentPage.value = val;
        await onLoadAllPartners({ prevPage: false, nextPage: false, reset: true });
        return;
      }
      if (currentPage.value > val) {
        await onLoadAllPartners({ prevPage: true, nextPage: false });
        currentPage.value = val;
        return;
      }
      await onLoadAllPartners({ prevPage: false, nextPage: true });
      currentPage.value = val;
    };

    onMounted(async () => {
      filterBy.value = "";
      isSortDesc.value = false;
      await initalLoadPartners();
      try {
        const res = await lookupIntegration();
        integrationsList.value = res.data.allIntegrations;
        return integrationsList.value;
      } catch (err) {
        console.error("Error", err);
      }
    });

    watch(filterBy, (newValue, oldValue) => {
      initalLoadPartners();
    });

    watch(sortBy, (newValue, oldValue) => {
      initalLoadPartners();
    });

    watch(isSortDesc, (newValue, oldValue) => {
      sortOrder.value = newValue ? "desc" : "asc";
      initalLoadPartners();
    });

    return {
      getPartnerList,
      filterBy,
      setPerPage,
      getPerPage,
      getLoading,
      initalLoadPartners,
      getTotalRows,
      fields,
      currentPage,
      pageOptions,
      onPageChanged,
      perPageChanged,
      isUserAdmin,
      sortBy,
      sortOrder,
      isSortDesc,
      partnerData,
      savePartner,
      modalRef,
      saving,
      integrationsList
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
.new-only-switch {
  margin-left: 20px;
}
</style>
